// layout- は MenuBar が左か下かのちがい
@custom-media --layout-lg  screen and (width >= 820px), print and (width >= 180mm);
@custom-media --layout-sm  screen and (width <  820px), print and (width <  180mm);
// content- はユーザーコンテンツをコンパクト表示するかどうか
@custom-media --content-lg screen and (width >= 640px), print and (width >= 140mm);
@custom-media --content-sm screen and (width <  640px), print and (width <  140mm);

:root {
    --system-font-family: Verdana, Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
    --system-font-size: 13px;
    --system-text-color: #332;
    --system-background-color: #fff;
}

@custom-selector :--user-content #content,#footer,#header,#menubar,#sidebar,#note;
// #contents は 3 カラムレイアウト全体
// #content は中央カラムのタイトルを除く本文領域
