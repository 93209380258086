/* vote.inc.php */
table td.vote_label,
#header table td.vote_label,
#footer table td.vote_label {
    color: #F5F5F5;
    background-color: #000;
}

table td.vote_td1,
#header table td.vote_td1,
#footer table td.vote_td1 {
    background-color: #FFF;
}

table td.vote_td2,
#header table td.vote_td2,
#footer table td.vote_td2 {
    background-color: #F5F5F5;
}
