@import "../../_shared/css/preset.css";
@import "_layout.css";
@import "_modules/_index.css";


/*********************** PCモードサポート ***********************/
body {
    -webkit-text-size-adjust: none;
}

/*********************** 基本フォント ***********************/
/* 見出しはつねにゴシック */
h1, h2, h3, h4, h5, h6 {
    font-family: var(--system-font-family);
}

/*********************** 文字間・行間 ***********************/
body {
    letter-spacing: 0.04em;
    overflow-wrap: break-word;
}
pre, table th, table td {
    overflow-wrap: normal;
}
* {
    line-height: 1.6em;
}
input, button {
    line-height: normal;
}

pre, dl, ol, ul, p, blockquote {
    margin-top: .7em;
    margin-bottom: .7em;
}

/*********************** ほか ***********************/
blockquote {
    margin-left: 32px;
}

body {
    background-color: #EEE;
    background-image: url('../image/note/note-bg_slash.png');
}

html {
    height: 100%;
}

body {
    height: 100%;
}

body {
    color: #000;
    /*margin: 0 2%;*/
    /* font-size は WikiSetting で決まる */
}
th, td {
    font-size: 1em;
}

div#body a {
    line-break: strict;
    word-break: break-all;
    word-wrap: break-word;
}

a {
    color: #333;
    background-color: inherit;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: #ddd;
    //text-decoration-thickness: 1px;
    text-underline-offset: 2px;
}

a:active,
a:visited {
    color: #333;
}

a:hover {
    color: #333;
    text-decoration-color: #000;
}

h1, h2 {
    font-size: 1.1em;
    color: inherit;
    background-color: #E4EBF2;
    padding: .3em;
    border: 0;
    margin: 0 0 .5em 0;
}

h3 {
    font-size: 1em;
    border-bottom: 3px solid #E4EBF2;
    border-top: 1px solid #E4EBF2;
    border-left: 10px solid #E4EBF2;
    border-right: 5px solid #E4EBF2;
    color: inherit;
    background-color: #FFFFFF;
    padding: .3em;
    margin: 0 0 .5em 0;
}

h4 {
    font-size: 1em;
    border-left: 18px solid #E4EBF2;
    color: inherit;
    background-color: #FFFFFF;
    padding: .3em;
    margin: 0 0 .5em 0;
}

h5, h6 {
    font-size: 1em;
    color: inherit;
    background-color: #E4EBF2;
    padding: .3em;
    border: 0;
    margin: 0 0 .5em 0;
}

h1.title {
    font-size: 1.33em;
    font-weight: bold;
    background-color: #E4EBF2;
    padding: 7px 0 7px 5px;
    border: 0;
    margin: 5px 0 10px 0;
}

dt {
    font-weight: bold;
    margin-top: 1em;
    margin-left: 1em;
}

dd {
    margin-left: 1em;
}

pre {
    border-top: #888899 1px solid;
    border-bottom: #DDDDEE 1px solid;
    border-left: #888899 1px solid;
    border-right: #DDDDEE 1px solid;
    padding: .5em;
    margin-left: 1em;
    margin-right: 2em;
    white-space: pre;
    color: black;
    background-color: #F5F5F5;
    overflow: auto;
}

img {
    border: none;
}

em {
    font-style: italic;
}

strong {
    font-weight: bold;
}

table thead td, table tfoot td {
    color: inherit;
    background-color: #D0D8E0;
}

table thead th, table tfoot th {
    color: inherit;
    background-color: #E0E8F0;
    font-size: 1em;
}

table {
    padding: 0;
    border: 0;
    margin: .5em auto .5em 0;
    text-align: left;
    color: inherit;
    border-collapse: collapse;
}

table th {
    padding: 5px;
    margin: 1px;
    font-size: 1em;
    text-align: center;
    color: inherit;
    background-color: #EEE;
    border: 1px solid #aaa;
}

table td {
    padding: 5px;
    margin: 1px;
    color: inherit;
    background-color: #FFF;
    border: 1px solid #aaa;
}

table.conflict_diff > thead th {
    background-color: #999;
    border: 1px solid #aaa;
    padding: 1px;
    text-align: left;
}

#header table,
#footer table {
    background-color: transparent;
}

#header table th,
#header table td,
#footer table th,
#footer table td {
    margin: 0;
    padding: 0;
    border-color: transparent;
    background-color: transparent;
}

#header table td h1.title {
    font-size: 30px;
    font-weight: bold;
    background-color: transparent;
    padding: 0;
    border: 0;
    margin: 0;
}

/** ヘッダの wiki 名 */
.title2 {
    color: #000;
    font-size: 19px;
    font-weight: bold;
    border-style: none;
    background-color: transparent;
}

ul.list1 {
    list-style-type: disc;
}

ul.list2 {
    list-style-type: circle;
}

ul.list3 {
    list-style-type: square;
}

ol.list1 {
    list-style-type: decimal;
}

ol.list2 {
    list-style-type: lower-roman;
}

ol.list3 {
    list-style-type: lower-alpha;
}

div#body ul.list1,
div#body ol.list1,
div#body dl.list1,
div#body ul.list2,
div#body ol.list2,
div#body dl.list2,
div#body ul.list3,
div#body ol.list3,
div#body dl.list3 {
    margin-left: 16px;
    padding-left: 16px;
}

span.noexists {
    color: inherit;
    background-color: #EEE;
}

.small {
    font-size: 90%;
}

.small1 {
    font-size: 90%;
}

.super_index {
    color: #DD3333;
    background-color: inherit;
    font-weight: bold;
    font-size: 10px;
    vertical-align: super;
}

a.note_super {
    color: #DD3333;
    background-color: inherit;
    font-weight: bold;
    font-size: 10px;
    vertical-align: super;
}

hr {
    border: 0;
    color: #777;
    background-color: #777;
    height: 1px;
}

hr.full_hr {
}

hr.note_hr {
}

/* sizex plugin */
.size1 {
    font-size: xx-small;
}
.size2 {
    font-size: x-small;
}
.size3 {
    font-size: small;
}
.size4 {
    font-size: medium;
}
.size5 {
    font-size: large;
}
.size6 {
    font-size: x-large;
}
.size7 {
    font-size: xx-large;
}

/* html.php/edit_form() */
.edit_form {
    clear: both;
}

/* pukiwiki.skin.*.php */
div#header {
    /*padding: 0;*/
    /*margin: 0 1%;*/
}

div#navigator {
    clear: both;
    padding: 4px 0 0 0;
    /*margin: 0 1%;*/
    white-space: nowrap;
}

/*td.menubar {*/
/*    width: 160px;*/
/*    vertical-align: top;*/
/*}*/

/*td.sidebar {*/
/*    width: 160px;*/
/*    vertical-align: top;*/
/*}*/

div#menubar {
/*    width: 160px;*/
/*    padding: 0;*/
/*    margin: 0 2px;*/
    word-break: break-all;
/*    overflow: hidden;*/
}

div#menubar ul,
div#menubar ol {
    margin: 0.5em;
    padding: 0 0 0 1em;
}

div#menubar ul li,
div#menubar ol li {
    margin: 0 0 0 0.4em;
}

div#menubar h2 {
    margin: 0 0 0 0;
    padding: 4px;
    border: 1px black solid;
    color: white;
    background-color: #000;
    text-align: center;
}

div#menubar h3 {
    margin: 0 0 0 0;
    padding: 4px;
    border: 1px black solid;
    background-color: #CDD4DB;
    text-align: center;
}

div#menubar h4 {
    margin: 0 0 0 0;
    padding: 4px;
    border: 1px black solid;
    background-color: #eee;
    text-align: center;
}

div#menubar h5 {
    margin: 0 0 0 0;
    padding: 4px;
    border: 1px black solid;
    color: white;
    background-color: #000;
    text-align: center;
}

div#sidebar {
    width: 160px;
    padding: 0;
    margin: 0 2px;
    word-break: break-all;
    overflow: hidden;
}

div#sidebar ul {
    margin: 0 0 0 .5em;
    padding: 0 0 0 1em;
}

div#sidebar ul li {
    margin: 0 0 0 0.4em;
}

div#sidebar h2, h3, h4, h5 {
    margin: 0 0 0 0;
    padding: 4px;
}

div#body {
    padding: 0;
}

div#note {
    clear: both;
    padding: 0;
    margin: 0 5px;
}

div#attach {
    clear: both;
    padding: 0;
    margin: 0 1%;
}

div#attach img {
    vertical-align: middle;
}

div.toolbar {
    clear: both;
    text-align: right;
    white-space: nowrap;
}

div#lastmodified {
    font-size: 10px;
    color: #666;
    padding: 0;
    margin: -8px 1% .5em;
    text-align: right;
}

div#related {
    font-size: 10px;
    padding: 0;
    margin: 16px 1% 0 1%;
}

div#footer {
    padding: 0;
}

div#preview {
    color: inherit;
    background-color: #FFFFFF;
}

img#logo {
    float: left;
    margin-right: 20px;
}

/* aname.inc.php */
.anchor {
}

.anchor_super {
    font-size: xx-small;
    vertical-align: super;
}

/* br.inc.php */
br.spacer {
}

/* clear.inc.php */
.clear {
    display: block;
    clear: both;
}

/* CounterPlugin.php */
div.counter {
    font-size: 70%;
}

/* diff.inc.php */
span.diff_added {
    color: blue;
    background-color: inherit;
}

span.diff_removed {
    color: red;
    background-color: inherit;
}

/* HrPlugin.php */
hr.short_line {
    text-align: center;
    width: 80%;
}

/* include.inc.php */
h5.side_label {
    text-align: center;
}

/* navi.inc.php */
ul.navi {
    margin: 0;
    padding: 0;
    text-align: center;
}

li.navi_none {
    display: inline;
    float: none;
}

li.navi_left {
    display: inline;
    float: left;
    text-align: left;
}

li.navi_right {
    display: inline;
    float: right;
    text-align: right;
}

/* new.inc.php */
span.comment_date {
    font-size: x-small;
}

span.new1 {
    color: red;
    background-color: transparent;
}

span.new3 {
    color: orangered;
    background-color: transparent;
    font-size: xx-small;
}

span.new5 {
    color: #008000;
    background-color: transparent;
    font-size: xx-small;
}

/* popular.inc.php */
span.counter {
    font-size: 70%;
}

ul.popular_list {
    padding: 0 0 0 .5em;
    margin: 0 0 0 .5em;
    border: 0;
    word-wrap: break-word;
    word-break: break-all;
}

/* recent.inc.php,showrss.inc.php */
ul.recent_list {
    padding: 0 0 0 .5em;
    margin: 0 0 0 1em;
    border: 0;
    word-wrap: break-word;
    word-break: break-all;
}

ul.recent_list li {
}

/* ref.inc.php */
div.img_margin {
    margin-left: 32px;
    margin-right: 32px;
}
#contents table div.img_margin {
    margin-left: 10%;
    margin-right: 10%;
}

div.img_nomargin {
    margin-left: -20px;
    margin-right: -20px;
}

div#topicpath {
    color: black;
    margin-top: 0.3rem;
}

blockquote {
    border: 1px solid #CCCCCC;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

blockquote p {
    margin: 0.5em 1em;
}

div.trackback {
    font-size: 80%;
    text-align: right;
}

div.prevnext {
}

div.prevnext_l {
    float: left;
}

div.prevnext_r {
    float: right;
}

/* for BugTrack/1 */
.dummyblock {
    width: 77%;
    float: left;
    display: block;
}

.center_form {
    text-align: center;
    padding: 4px 0 8px;
    margin: 0;
}

span.add_word {
    background-color: #FFFF66;
}

span.remove_word {
    background-color: #A0FFFF;
}

/* pukiwiki extend anchor */
img.ext {
    margin-left: 2px;
    vertical-align: baseline;
}

img.inn {
    margin-left: 2px;
    vertical-align: baseline;
}

/* headarea extend */
#header .style_table,
#footer .style_table {
    background-color: transparent;
}

#header .style_table .style_td,
#footer .style_table .style_td {
    margin: 0;
    padding: 0;
    border-color: transparent;
    background-color: transparent;
}

#header .style_table .style_td h1.title {
    font-size: 30px;
    font-weight: bold;
    background-color: transparent;
    padding: 0;
    border: 0;
    margin: 0;
}

#header a {
    text-decoration: none;
}

/* extra attach table */
table.attach_table {
    padding: 0;
    border: 0;
    margin: auto;
    text-align: left;
    color: inherit;
    background-color: #CCCCCC;
}

th.attach_th {
    padding: 2px;
    margin: 1px;
    text-align: center;
    color: inherit;
    background-color: #FFEECC;
}

td.attach_td1 {
    background-color: #FFFFFF;
}

td.attach_td2 {
    background-color: #CCFFCC;
}

/* navibar2.inc.php */
div#navigator2 {
    margin: 4px 0;
    padding: 0 4px;
    left: 0;
    width: 100%;
    background-color: #fff0dd;
    border: 1px #ffcc99 solid;
    clear: both;
    white-space: nowrap;
}

div.naviblock {
    padding: 2px 5px 2px 5px;
    border: 1px #ffcc99 solid;
    border-top: #fff0dd 1px solid;
    background-color: #fff0dd;
    visibility: hidden;
    position: absolute;
    z-index: 10;
}

div.menuitem {
    padding: 1px 0;
}

div.naviblock a {
    padding: 1px 8px;
    color: #a63d21;
    border: 1px #fff0dd solid;
    text-decoration: none;
    display: block;
}

div.naviblock a:active {
    border: 1px #ff9933 solid;
    color: #a63d21;
    background-color: transparent;
    text-decoration: none;
}

div.naviblock a:visited {
    color: #a63d21;
    text-decoration: none;
}

div.naviblock a:hover {
    border: 1px #ff9933 solid;
    color: #ff0000;
    background-color: #ffeecc;
    text-decoration: none;
}

/* 1.4.6u1 added */
span.linkwarn {
    font-size: xx-small;
    font-weight: bold;
    color: #f00;
    background-color: #ff6;
}

span.linkwarn a {
    color: #f00;
    background-color: #ff6;
}

.toolbar a,
.toolbar a:active,
.toolbar a:visited {
    text-decoration: none;
}

.toolbar a:hover {
    background-color: Black;
    color: #ccc;
}

/*div#cads {*/
/*    line-height: normal;*/
/*    letter-spacing: 0;*/
/*    margin-bottom: 1em;*/
/*}*/

/*p.cads {*/
/*    line-height: normal;*/
/*    background-color: #000000;*/
/*    text-decoration: none;*/
/*}*/

/*.cads a {*/
/*    color: #FFFFFF;*/
/*    border-style: none;*/
/*}*/

pre > code {
    white-space: pre-wrap;
    word-break: break-all;
}

.wiki-source > code {
    font-family: var(--system-font-family);
}
.wiki-source:not(pre) > code {
    white-space: pre-wrap;
}
pre.wiki-source > code {
    white-space: pre;
}

.result-message {
    margin: 1em 0;
    padding: 10px 20px;
    background-color: #ffe;
    border: 1px solid #666;
    border-radius: 6px;
    color: #333;
}
.result-message .result-message-icon {
    color: #666;
    font-size: 14px;
}

.warning-message {
    margin: 0 0 1em;
    padding: 10px 20px;
    background-color: #f8d7da;
    border: 1px solid #666;
    border-radius: 6px;
    color: #333;
}
.warning-message .warning-message-icon {
    color: #666;
    font-size: 14px;
}

div#control-panel {
    margin: 3px 0 0;
    padding: 0.1em 0.5em;
}

.heading-after-first {
    margin-top: 8px;
}

input[name=attach_file],
input[name=attach] {
    margin: 8px 0;
}
