/* calendar*.inc.php */
table.calendar2_frame {
    margin: 1px;
}

table.minicalendar_frame {
    margin: 0;
}

table.calendar2_frame > tbody > tr > td{
    padding: 1px;
}

table.minicalendar_frame > tbody > tr > td{
    padding: 8px;
}

.style_calendar {
    background-color: #999;
}

.style_td_caltop {
    background-color: #EEF5FF;
    font-size: 80%;
}

.style_td_today {
    background-color: #FFFFDD;
}

.style_td_sat {
    background-color: #DDE5FF;
}

.style_td_sun {
    background-color: #FFEEEE;
}

.style_td_blank {
    background-color: #EEF5FF;
}

.style_td_day {
    background-color: #EEF5FF;
}

.style_td_week {
    background-color: #DDE5EE;
    font-size: 80%;
}
