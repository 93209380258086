/* minicalendar.inc.php */
.ministyle_calendar {
    background-color: #999;
}

.ministyle_td_caltop {
    background-color: #FFF;
}

.ministyle_td_today {
    background-color: #FFFFDD;
}

.ministyle_td_sat {
    background-color: #DDE5FF;
}

.ministyle_td_sun {
    background-color: #FFEEEE;
}

.ministyle_td_blank {
    background-color: #FFFFFF;
}

.ministyle_td_day {
    background-color: #FFFFFF;
}

.ministyle_td_week {
    background-color: #E0E0E0; /*#DDE5EE;*/
}

/* minicalendar+public_holiday view */
