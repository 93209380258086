#responsive-navigation-menubar-drawer .drawer-content-inner {
    padding: 10px;
    background-color: var(--user-content-background-color);

    & .dom-source-placeholder {
        box-sizing: border-box;
        border: 1px solid #000000;
        padding: 5px;
        background-color: #FFFFFF;
    }
}
