.included-page-link-box {
    /* これまでの h1 と同じ見た目 */
    font-size: 1.1em;
    color: inherit;
    background-color: #E4EBF2;
    padding: .3em;
    border: 0;
    margin: 0 0 .5em 0;

    /*noinspection CssNoGenericFontName*/
    font-family: var(--system-font-family);

    /* UA の h1 デフォルト */
    font-weight: bold;
}
