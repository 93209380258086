/*********************** レイアウト枠 ***********************/

body {
    margin: 0;
    padding: 0 15px;
}

.container-wrapper {
    max-width: 850px; /* wiki 設定で上書きさせる */
    min-width: 850px; /* wiki 設定で上書きさせる */
    margin: 0 auto;
    text-align: left;
}

.container {
    position: relative;
}

.clearfix::after {
    content: "";
    display: block;
    clear: both;
}

/*********************** 3カラムレイアウト枠 ***********************/

.columns-container {
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    min-height: 568px;
}
.columns-container .column-left-top,
.columns-container .column-left,
.columns-container .column-center,
.columns-container .column-bottom {
    min-width: 0; /* はみ出る対策 */
    border-left: 1px solid black;
    border-right: 1px solid black;
    background-color: #FFFFFF;
}
.columns-container .column-left-top {
    grid-row: 1 / span 1;
}
.columns-container .column-left {
    grid-row: 2 / span 2;
    min-height: 100vh;
}
.columns-container .column-center {
    grid-row: 1 / span 2;
}
.columns-container .column-bottom {
    grid-row: 3 / span 1;
}

.columns-container:not(.has-left-column) {
    grid-template-columns: 1fr;
}
.columns-container:not(.has-left-column) .column-center {
    grid-column: 1 / span 1;
}
.columns-container:not(.has-left-column) .column-bottom {
    grid-column: 1 / span 1;
}

.columns-container.has-left-column {
    grid-template-columns: 180px 20px 1fr; /* 160 + 10 * 2 wiki 設定で上書きされる */
}
.columns-container.has-left-column .column-left {
    grid-column: 1 / span 1;
}
.columns-container.has-left-column .column-center {
    grid-column: 3 / span 1;
}
.columns-container.has-left-column .column-bottom {
    grid-column: 3 / span 1;
}


.container-wrapper {
    min-width: auto;
}

.default-header {
    display: none;
}

.toolbar-container-all .toolbox-searchbar {
    width: 150px;
}
.toolbar-container-all .toolbox-searchbar form {
    text-align: right;
    display: flex;
    justify-content: flex-end;
}
.toolbar-container-all .toolbox-searchbar input[name="word"] {
    width: 84px;
    height: 24px;
    font-size: 12px;
    margin-right: 4px;
}
.toolbar-container-all .toolbox-searchbar input[type=submit] {
    padding: 0.25em 0.375em;
}
.toolbar-container-desktop {
    display: block;
}
.toolbar-container-mobile {
    display: none;
}

@media (--layout-sm) {
    body {
        padding: 0;
        height: auto !important; /* スマホのときは画面下部の広告に余白制御を許さないと */
    }
    .container-wrapper {
        max-width: none;
        min-width: 320px;
        border: none;
    }
    .container {
        margin: 0;
    }
    .columns-container {
        display: block;
    }
    .columns-container.has-left-column .column-center {
        padding: 0;
    }
    .columns-container .column-left {
        padding-top: 1em;
        min-height: auto;
    }
    .columns-container .column-left-top,
    .columns-container .column-left,
    .columns-container .column-center,
    .columns-container .column-bottom {
        border-left: none;
        border-right: none;
    }
    .columns-container .column-left {
        border: 1px solid #000000;
        margin: 20px 12px;
    }
    .columns-container .column-bottom {
        border-top: 1px solid #000000;
        border-bottom: 1px solid #000000;
        margin-top: 20px;
    }
    .columns-container .column-left-top {
        border-bottom: 1px solid #000000;
        margin-top: 0;
        margin-bottom: 0;
    }
    .columns-container .column-center {
        border-bottom: 1px solid #000000;
    }

    .columns-container.no-menu-content .column-center {
        padding-bottom: 20px;
        border-bottom: none;
    }
    .columns-container.no-menu-content .column-left {
        display: none;
    }
    .columns-container.no-menu-content .column-bottom {
        border-top: none;
        padding-top: 1px;
        margin-top: 0;
    }

    div.img_margin {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .columns-container .column-left-top #header {
        padding: 1px 0;
    }
    .default-header {
        display: block;
        padding: 8px;
        margin: -1px 0;
        background: black;
    }
    .default-header * {
        color: white !important;
        background: none !important;
    }
    .footer-block {
        margin: 0 -3px;
        padding: 0 8px;
    }

    .toolbar-container-desktop {
        display: none;
    }
    .toolbar-container-mobile {
        display: block;
        width: 150px;
    }
    .toolbar-container-mobile > .toolbar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 8px;
    }
    .toolbar-container-all .toolbox-searchbar {
        width: 150px;
    }
}


/*********************** コンテンツ内基調整 ***********************/

#body {
    overflow-x: visible;
}

#contents {
    padding-left: 10px;
    padding-right: 10px;
}

#menubar {
    margin: 10px;
}

.toolbar-container-all {
    background-color: black;
    width: 540px;
    margin-left: auto;
    padding-bottom: 4px;
    padding-right: 8px;
    border-bottom-left-radius: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (--layout-sm) {
        width: 300px;
    }
}

.toolbar a.menu-in-nav {
    color: white;
}

.h-scrollable {
    overflow-x: auto;
    text-align: left;
}

#footer {
    margin: 0 5px 0;
}
#footer .footer-block {
    margin-top: 1em;
    padding: 2px 10px;
    background-color: black;
    color: white;
}
#footer .footer-block-item-left {
    float: left;
    text-align: left;
    /*max-width: 60%;*/
}
#footer .footer-block-item-right {
    float: right;
    text-align: right;
    white-space: nowrap;
}

#footer .footer-pageload,
#footer .footer-mixirss {
    display: inline-block;
}

#footer .footer-block a {
    padding: 0 5px;
}
#footer .footer-block a,
#footer .footer-block a:active,
#footer .footer-block a:visited {
    text-decoration: none;
    color: #FF6600;
}

#footer .footer-block a:hover {
    color: #000;
    background-color: #FF6600;
}

#breadcrumbs {
    float: left;
}

#system-icon-container {
    float: right;
    display: flex;
    align-items: center;
    position: relative;

    // 内部の高さの予測値で固定したい
    min-height: 29px;

    & .single-system-icon {
        display: inline-block;
        padding: 0 8px;
    }

    & .is-frozen-icon {
        border: none;
        color: #888888;
        text-decoration: none;
    }
}
#control-panel {
    padding: 0.2em 0.5em 0;
    font-size: 11px;
}
#control-panel a {
    border: none;
    color: #888888;
    text-decoration: none;
}

select#report_comment {
    width: 100%;
    height: 2em;
    margin: 0.5em 0;
}
